import React from 'react'
import Image from 'components/ImgOptimized'
import { ServiceofferingssectionStyles } from './Serviceofferingssection.styles'
import Button from 'components/Button/Button'

function Serviceofferingssection(props) {
  const { ServiceofferingsData } = props

  return (
    <ServiceofferingssectionStyles>
      <div className="container">
        {ServiceofferingsData.title && (
          <div className="heading-wrapper">
            <h2>{ServiceofferingsData.title}</h2>
          </div>
        )}
        <div className="offering-list">
          {ServiceofferingsData.Serviceofferings.map(
            (CasePowerToolsProp, i) => {
              return (
                <div key={i} className="offeringListRow">
                  <div className="offeringDetails ">
                    {CasePowerToolsProp.Title ? (
                      <div className="offeringDetailsHeading">
                        {CasePowerToolsProp.Title ? (
                          <h3 className="h4">{CasePowerToolsProp.Title}</h3>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <p>{CasePowerToolsProp.para}</p>
                    {CasePowerToolsProp.typeList && (
                      <ul className="typeList">
                        {CasePowerToolsProp.typeList.map((list, j) => {
                          return (
                            <li key={`${i}${j}`}>
                              <h4 className="type-title">{list.title}</h4>
                              {list.para && <p>{list.para}</p>}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                    {CasePowerToolsProp.buttonName && (
                      <Button
                        type={CasePowerToolsProp.LinkType}
                        targetBlank={CasePowerToolsProp.targetBlank}
                        buttonLink={CasePowerToolsProp.buttonLink}
                        buttonName={CasePowerToolsProp.buttonName}
                      />
                    )}
                  </div>
                  <div className="offeringImages">
                    <Image
                      fluid={props.items[i]}
                      alt={CasePowerToolsProp.Title}
                      lazyload={{
                        enabled: true,
                        once: true,
                        offset: 1000,
                        heigth: 411,
                      }}
                    />
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    </ServiceofferingssectionStyles>
  )
}

export default Serviceofferingssection
