import styled from 'styled-components'
import { xl, lg, md, white, themeColor, sm, sh } from 'config/variables'

export const ServiceofferingssectionStyles = styled.div`
  margin-bottom: 140px;
  ${xl(`
    margin-bottom: 100px;
  `)}
  ${lg(`
    margin-bottom: 90px;
  `)}
   ${md(`
    margin-bottom: 40px;
  `)}
  .offering-list {
    margin-top: 100px;
  ${xl(`
    margin-top: 80px;
  `)}
  ${lg(`
    margin-top: 60px;
  `)}
   ${md(`
    margin-top: 40px;
  `)}
  }
  .offeringListRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 100px;
    ${xl(`
        padding-bottom: 80px;
    `)}
    ${lg(`
        flex-direction: column;
        padding-bottom: 60px;
    `)}
    ${md(`
        flex-direction: column;
        padding-bottom: 40px;
    `)}
    &:last-child {
      padding-bottom: 0px;
    } 
    &:nth-child(odd) {
      .offeringDetails {
        order: 2;
        padding-left: 23px;
        padding-right: 0;
        ${lg(`
           padding: 0;
           order: 2; 
        `)}
        ${md(`
          flex-direction: column; 
        `)}
      }
      .offeringImages { 
        ${lg(` 
          order: 1;
          padding-bottom: 50px;
        `)} 
        ${md(` 
          padding-bottom: 30px;
        `)}
      }
    }
    .offeringDetails {
      flex: 0 1 592px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-right: 30px;
      ${lg(`
        padding: 0;
        order: 2;
        flex: 1 1 auto;
      `)} 
      .h4 {
        margin-bottom: 12px;
      }
      p {
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        letter-spacing: -0.13px;
        ${sm(` 
          font-size: 18px;
          line-height: 24px;
        `)}
      }
      .typeList  {
        list-style: none;
        li {
          position: relative;
          padding-left: 30px;
          &::after {
            content:"";
            position: absolute;
            top: 6px;
            left: 0;
            height: 14px;
            width: 14px;
            border-radius: 50%;
            background: #f19caf;
          }
          .type-title {
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            margin: 0 0 9px;
          }
        }
      }
      .button {
        background: ${themeColor}; 
        font-size: 20px;
        font-weight: 400;
        color: ${white}; 
        letter-spacing: -0.16px;
        padding: 13px 26px 12px;
        line-height: 27px;
        margin-top:20px;
        text-transform: uppercase;
        ${sh(`
          font-size: 17px; 
          line-height: 19px;
        `)}
        ${md(`
          font-size: 16px; 
          margin: 12px 0 0;
        `)}
        ${sm(`
          font-size: 14px;
          line-height: 17px;
          margin-top:0px;
        `)}
        &:before,
        &:after {
          background: ${white};
        }
        &:hover {
          color: ${themeColor};
        }
      }
    }
    .offeringImages {
      flex: 0 1 524px; 
      ${lg(`
        padding: 0;
        order: 1; 
        width:100%;
        flex:1 1 auto;
        padding-bottom: 50px;
      `)} 
      ${md(` 
        padding-bottom: 30px;
      `)}
    }
  } 
`
