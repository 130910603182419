import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import styled from 'styled-components'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import SEO from 'components/Seo'
import { SoftwareTestingProps } from 'components/Props/software-testing'
import { md } from 'config/variables'
import { graphql } from 'gatsby'
import { images } from 'config/images'

import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import Serviceofferingssection from 'components/Serviceofferingssection/Serviceofferingssection'
import RangeServiceListSec from 'components/RangeServiceListSec/RangeServiceListSec'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const SoftwareTestingPage = styled.div`
  .banner {
    .banner-content {
      max-width: 880px;
      p {
        max-width: 810px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
  .feature-resource-section {
    background-image: linear-gradient(to top, #eef2ff, #fff);
    .contact-info-card {
      display: none;
    }
  }
  .developsoft-section {
    ${md(`
      display: none;
    `)}
  }
  .industries-service {
    ${md(`
      display: none;
    `)}
  }
  .getintouch-section {
    .awards-recognition-blk {
      ${md(`
        display: none;
      `)}
    }
  }
`

const SoftwareTesting = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
    props.data.process6.childImageSharp.fluid,
    props.data.process7.childImageSharp.fluid,
    props.data.process8.childImageSharp.fluid,
  ]

  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let offerings = [
    props.data.stsTestAutomationServices.childImageSharp.fluid,
    props.data.stsMicroservicesTesting.childImageSharp.fluid,
    props.data.stsApiTesting.childImageSharp.fluid,
    props.data.stsPerformanceTesting.childImageSharp.fluid,
    props.data.stsSecurityTesting.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="software-testing services-page"
      location={location}
      data={{
        img: images.contactHiren,
        img2x: images.contactHiren_2x,
        name: 'Hiren Dhaduk',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Software Testing Services | QA Services "
        description="We are a leading software testing services company with a client from Fortune 1000. Deliver amazing software experiences to your customers with our QA services."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/software-testing/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/services/software-testing/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <SoftwareTestingPage>
        <Banner
          bannerData={SoftwareTestingProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={SoftwareTestingProps} />
          <Serviceofferingssection
            ServiceofferingsData={SoftwareTestingProps.serviceofferingsProps}
            items={offerings}
          />
        </div>
        <CustomDevelopment
          customServicedata={SoftwareTestingProps.customDevProps}
        />
        <RangeServiceListSec
          rangeServiceListData={SoftwareTestingProps.rangeServiceListDataProps}
        />
        <CaseStudySlider caseStudydata={SoftwareTestingProps} {...props} />
        <ProcessFlow
          ProcessFlowData={SoftwareTestingProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <SimformGuaranteeSec
          SimformGuaranteeProps={SoftwareTestingProps.SimformGuaranteeData}
          posterImage={posterImage}
        />
        <FaqSection faqSectiondata={SoftwareTestingProps} />
        <FeatureResources
          featureresourceData={SoftwareTestingProps}
          {...props}
        />
      </SoftwareTestingPage>
    </Layout>
  )
})

export default SoftwareTesting

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/software-testing-dev-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-test-automation@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-testing-vs-functional@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/testing-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 683) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(
      relativePath: { regex: "/evaluation-priority-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/test-plan-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/test-designing-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 366) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(
      relativePath: { regex: "/implementing-test-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/test-case-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 378) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process6: file(relativePath: { regex: "/manual-testing-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 288) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process7: file(relativePath: { regex: "/test-closure-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 371) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process8: file(
      relativePath: { regex: "/continuous-delivery-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 364) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stsTestAutomationServices: file(
      relativePath: { regex: "/sts-test-automation-services@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stsMicroservicesTesting: file(
      relativePath: { regex: "/sts-microservices-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stsApiTesting: file(relativePath: { regex: "/sts-api-testing@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stsPerformanceTesting: file(
      relativePath: { regex: "/sts-performance-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stsSecurityTesting: file(
      relativePath: { regex: "/sts-security-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
